
import { Component, Vue } from 'vue-property-decorator'
import { apiFinanceDataCenter } from '@/api/finance/finance'

@Component
export default class FinanceProfile extends Vue {
    /** S Data **/
    ProfileData = {
        distribution_data: {}
    }
    /** E Data **/

    /** S Methods **/
    // 获取财务概况
    financeDataCenter() {
        apiFinanceDataCenter()
            .then(res => {
                this.ProfileData = res
            })
            .catch(res => {})
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        this.financeDataCenter()
    }
    /** E Life Cycle **/
}
